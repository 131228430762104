module.exports = function (element) {
    var titles_original = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.whitespace,
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        prefetch: {
            url: element.dataset.url,
            cache: false
        }
    });

    $(element).typeahead(null, {
        name: 'titles_original',
        source: titles_original
    });
}