import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["checkboxAll", "checkbox", "copyDestination", "conditionalButton"]

    initialize() {
        this.toggle = this.toggle.bind(this)
        this.refresh = this.refresh.bind(this)
    }

    connect() {
        this.refresh()
    }

    disconnect() {
    }


    toggle(event) {
        event.preventDefault()

        this.checkboxTargets.forEach(checkbox => {
            checkbox.checked = event.target.checked
            this.triggerInputEvent(checkbox)
        })
    }

    refresh() {
        const checkboxesCount = this.checkboxTargets.length
        const checkboxesCheckedCount = this.checked.length

        this.checkboxAllTarget.checked = checkboxesCheckedCount > 0
        this.checkboxAllTarget.indeterminate = checkboxesCheckedCount > 0 && checkboxesCheckedCount < checkboxesCount

        if (this.hasConditionalButtonTarget) {
            this.conditionalButtonTargets.forEach(button => button.disabled = checkboxesCheckedCount <= 0)
        }
    }


    triggerInputEvent(checkbox) {
        const event = new Event('input', {bubbles: false, cancelable: true})

        checkbox.dispatchEvent(event)
    }

    copy(event) {
        if (this.hasCopyDestinationTarget) {
            const input = event.currentTarget

            this.copyDestinationTargets.forEach(target => {
                if (input.checked) {
                    let hiddenField = document.createElement("input")
                    hiddenField.setAttribute("type", "hidden")
                    hiddenField.setAttribute("name", input.name)
                    hiddenField.setAttribute("value", input.value)

                    target.append(hiddenField)
                } else {
                    target.querySelectorAll(`input[value="${input.value}"]`)
                        .forEach(checkbox => checkbox.remove())
                }
            })
        }
    }

    get checked() {
        return this.checkboxTargets.filter(checkbox => checkbox.checked)
    }

    get unchecked() {
        return this.checkboxTargets.filter(checkbox => !checkbox.checked)
    }
}