module.exports = function (element) {
    var vendor_ids = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.whitespace,
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        prefetch: {
            url: element.dataset.url,
            cache: false
        }
    });

    $(element).typeahead(null, {
        name: 'vendor_ids',
        source: vendor_ids
    });
}