var initCrews = require('../forms/crews')
var initDatepickers = require('../forms/datepicker')
var initConditionals = require('../forms/conditionals')
var initRemoteTitles = require('../forms/remote_titles')
let removeFields = require("./remove_fields")

module.exports = class addFields {

    // This executes when the function is instantiated.
    constructor() {
        this.links = document.querySelectorAll('.add_fields');
        this.iterateLinks();
    }

    iterateLinks() {
        // If there are no links on the page, stop the function from executing.
        if (this.links.length === 0) return;
        // Loop over each link on the page. A page could have multiple nested forms.
        this.links.forEach((link) => {
            link.addEventListener('click', (e) => {
                this.handleClick(link, e);
            });
        });
    }

    handleClick(link, e) {
        // Stop the function from executing if a link or event were not passed into the function.
        if (!link || !e) return;
        // Prevent the browser from following the URL.
        e.preventDefault();
        // Save a unique timestamp to ensure the key of the associated array is unique.
        let time = new Date().getTime();
        // Save the data id attribute into a variable. This corresponds to `new_object.object_id`.
        let linkId = link.dataset.id;
        // Create a new regular expression needed to find any instance of the `new_object.object_id` used in the fields data attribute if there's a value in `linkId`.
        let regexp = linkId ? new RegExp(linkId, 'g') : null;
        // Replace all instances of the `new_object.object_id` with `time`, and save markup into a variable if there's a value in `regexp`.
        let newFields = regexp ? link.dataset.fields.replace(regexp, time) : null;

        // Add the new markup to the form if there are fields to add.
        if (newFields) {
            if (link.dataset.target) {
                document.querySelector(link.dataset.target + " a.add_fields").insertAdjacentHTML('beforebegin', newFields)
            } else {
                link.insertAdjacentHTML('beforebegin', newFields)

                if (link.previousSibling.querySelector('.crew-name')) {
                    initCrews(link.previousSibling.querySelector('.crew-name'))
                }
            }

            initDatepickers()

            $('.kt-select2').select2();

            $('.kt-select2-countries').select2({
                language: "de",
                width: '100%',
                templateSelection: function (option) {
                    if(!option.id) {
                        return option.text;
                    }
                    if(!option.element.dataset.nameShort) {
                        return option.text;
                    }
                    return option.element.dataset.nameShort;
                }
            });

            $('.kt-selectpicker').selectpicker();

            initConditionals();

            if(document.getElementById('clip_wdrmg_profit_center')) {
                $('.box-set-title-container [id*=wdrmg_profit_center]').attr('placeholder', $('#clip_wdrmg_profit_center').val())
            }

            if(document.getElementById('clip_licensor_share')) {
                $('.box-set-title-container [id*=licensor_share]').attr('placeholder', $('#clip_licensor_share').val())
            }

            if(document.getElementById('box_set_wdrmg_profit_center')) {
                $('.box-set-title-container [id*=wdrmg_profit_center]').attr('placeholder', $('#box_set_wdrmg_profit_center').val())
            }

            if(document.getElementById('box_set_licensor_share')) {
                $('.box-set-title-container [id*=licensor_share]').attr('placeholder', $('#box_set_licensor_share').val())
            }

            if(document.getElementById('box_set_licensor_id')) {
                $('.box-set-title-container [id*=licensor_id]').attr('data-copy-value', $('#box_set_licensor_id').val())
            }


            document.querySelectorAll('.remote-titles').forEach(element => {
                initRemoteTitles(element);
            });

            new removeFields();
        }
    }

}