import {Controller} from "@hotwired/stimulus"
import {Turbo} from "@hotwired/turbo-rails"

export default class extends Controller {
    static targets = ["container"]

    connect() {
    }

    containerTargetConnected(element) {
        $('.kt-select2').select2({
            language: "de",
            width: '100%'
        })
        KTApp.initTooltips()
    }

    change(event) {
        const platform_id = event.target.value

        fetch(`/admin/platforms/${platform_id}/sub_channels`, {
            headers: {
                accept: "text/vnd.turbo-stream.html",
            }
        }).then(response => response.text())
            .then(html => {
                Turbo.renderStreamMessage(html);
            })
    }
}