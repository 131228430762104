class SalesDelivery {
    constructor() {
        this.repeater = $('.distribution_countries_repeater')
    }

    init() {
        let self = this;

        this.repeater.repeater({
            isFirstItemUndeletable: true,
            initEmpty: false,
            show: function () {
                $(this).slideDown();

                let name = $(this).find('.kt-select2-countries').attr('name').replace("distribution_country_ids[]", "distribution_country_ids");

                $(this).find('.select2-container').remove();
                $(this).find('.kt-select2-countries').attr('data-select2-id', Math.floor(Math.random() * 100));

                $('.kt-select2-countries').select2({
                    language: "de",
                    width: '100%',
                    templateSelection: function (option) {
                        if(!option.id) {
                            return option.text;
                        }
                        if(!option.element.dataset.nameShort) {
                            return option.text;
                        }
                        return option.element.dataset.nameShort;
                    }
                });

                $(this).find('.kt-select2-countries').attr('name', name);
            },

            hide: function (deleteElement) {
                if (confirm('Are you sure you want to delete this element?')) {
                    $(this).slideUp(deleteElement);
                }
            },
            ready: function () {
                self.repeater.find('.kt-select2-countries').each(function (index) {
                    let name = $(this).attr('name').replace("distribution_country_ids[]", "distribution_country_ids");
                    $(this).attr('name', name);
                })
            }
        });
    }
}

export default SalesDelivery