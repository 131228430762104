import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static values = {
        show: {type: Boolean, default: false}
    }

    connect() {
        $(this.element).modal({
            show: this.showValue
        })
    }
}