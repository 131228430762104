module.exports = function () {
    let isAdvancedUpload = function () {
        let div = document.createElement('div');
        return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window;
    }();

    let previewFile = function (dropBox, file) {
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onloadend = function () {
            let img = document.createElement('img')
            img.src = reader.result
            img.classList.add('img-fluid')
            dropBox.querySelector('.preview').innerHTML = "";
            dropBox.querySelector('.preview').appendChild(img)
        }
    }

    if (isAdvancedUpload) {
        document.querySelectorAll('.drop-box').forEach(function (element) {
            let droppedFiles = false;

            $(element).on('drag dragstart dragend dragover dragenter dragleave drop', function (e) {
                e.preventDefault();
                e.stopPropagation();
            })
                .on('dragover dragenter', function () {
                    element.classList.add('is-dragover');
                })
                .on('dragleave dragend drop', function () {
                    element.classList.remove('is-dragover');
                })
                .on('drop', function (e) {
                    droppedFiles = e.originalEvent.dataTransfer.files;
                    [...droppedFiles].forEach(function (file) {
                        previewFile(element, file)
                    })

                    element.querySelector('input').files = droppedFiles;
                });

            $(element).find('input').on('change', function () {
                let input = this
                if (input.files && input.files[0]) {
                    var reader = new FileReader();

                    reader.onload = function () {
                        previewFile(element, input.files[0])
                    }

                    reader.readAsDataURL(input.files[0]);
                }
            })
        })
    }
}