import FixedColumns from "../fixed_columns";

class PublicTitleExclusiveStatuses {
    constructor(translations) {
        this.translations = translations
        this.table = $('#public-title-exclusive-statuses')
        this.detailRows = []
        this.order = [
            [2, 'asc'],
            [0, 'asc']
        ];

        this.columnDefs = [
            {
                targets: 0,
                type: 'html',
            },
            {
                targets: -1,
                orderable: false,
            }
        ];
        this.fixedLeftColumnsCount = 1;
    }

    init() {
        if (!this.table.length) {
            return null;
        }

        let self = this;

        const options = {
            // DOM Layout settings
            dom: `<'row'<'col-sm-12'tr>>
    		<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7 dataTables_pager'lp>>`,

            // Order settings
            order: self.order,

            lengthMenu: [10, 25, 50, 100],
            pageLength: 25,
            paging: true,

            autoWidth: false,
            bSortCellsTop: true,
            scrollX: true,
            scrollY: false,
            fixedColumns: {
                leftColumns: self.fixedLeftColumnsCount,
                rightColumns: 1
            },
            colReorder: {
                enable: true,
                fixedColumnsLeft: 1,
                fixedColumnsRight: 1,
            },

            columnDefs: self.columnDefs,

            language: self.translations,

            "drawCallback": function (settings) {
                $('.dataTables_scrollBody thead tr#filter-row').remove()
            },
            "initCallback": function (settings) {
                $('.dataTables_scrollBody thead tr#filter-row').remove()
            },
            "rowCallback": function (settings) {
                $('.dataTables_scrollBody thead tr#filter-row').remove()
            }
        };

        let table = this.table.DataTable(options);

        new FixedColumns(table, options).init();

        setTimeout(function () {
            $.fn.dataTable
                .tables({visible: true, api: true})
                .columns.adjust()
                .fixedColumns().relayout();

            $('.dataTables_scrollBody thead tr#filter-row').remove()
        }, 1000)

    }
}

export default PublicTitleExclusiveStatuses