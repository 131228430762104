module.exports = function (element) {
    var series = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.obj.whitespace("name"),
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        prefetch: {
            url: element.dataset.url,
            cache: false
        },
        identify: function(obj) { return obj.vendor_id; },
    });

    $(element).typeahead(null, {
        name: 'series',
        source: series,
        displayKey: 'name',
    });

    $(element).bind('typeahead:select', function(ev, suggestion) {
        $('#title_series_vendor_id').val(suggestion.vendor_id).prop("readonly", "readonly")
    });
}