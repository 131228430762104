"use strict";
require("@rails/ujs").start()
require("@rails/activestorage").start()
import "./channels"
import {Application} from "@hotwired/stimulus"
import {definitionsFromContext} from "@hotwired/stimulus-webpack-helpers"

const application = Application.start()
const context = require.context("./controllers", true, /\.js$/)
application.load(definitionsFromContext(context))


import {Turbo} from "@hotwired/turbo-rails"

Turbo.session.drive = false

//** Begin: Global mandatory plugins
window.jQuery = window.$ = require("jquery");
require("bootstrap");
require("block-ui");
require("autosize");
require("clipboard");
window.moment = require("moment");
window.Sticky = require("sticky-js");
//window.Chart = require("chart.js");
//window.Raphael = require("raphael");
window.Cookies = require("js-cookie");
window.Popper = require("popper.js");
require("jquery-form");

// Tooltips
import Tooltip from "tooltip.js";

window.Tooltip = Tooltip;

// Perfect-Scrollbar
require("perfect-scrollbar/css/perfect-scrollbar.css");
window.PerfectScrollbar = require("perfect-scrollbar/dist/perfect-scrollbar");
//** End: Globally mandatory plugins


//** Begin: Global optional plugins
// Owl.Carousel
// require("owl.carousel/dist/assets/owl.carousel.css");
// require("owl.carousel/dist/assets/owl.theme.default.css");
// require("owl.carousel");

// Daterangepicker
require("bootstrap-daterangepicker/daterangepicker.css");
require("bootstrap-daterangepicker");

// Bootstrap-Select
require("bootstrap-select/dist/css/bootstrap-select.css");
require("bootstrap-select");

// Bootstrap-Session-Timeout
// require("../../vendor/assets/demo1/src/assets/plugins/bootstrap-session-timeout/dist/bootstrap-session-timeout.js");
//
// // jQuery-Idletimer
// require("../../vendor/assets/demo1/src/assets/plugins/jquery-idletimer/idle-timer.js");

// Bootstrap-Switch
require("bootstrap-switch/dist/css/bootstrap3/bootstrap-switch.css");
require("bootstrap-switch");
require("../../vendor/assets/demo1/src/assets/js/global/integration/plugins/bootstrap-switch.init.js");

// Sweetalert2
// require("sweetalert2/dist/sweetalert2.css");
// import swal from "sweetalert2/dist/sweetalert2";
// window.swal = swal;
// require("es6-promise-polyfill/promise.min.js");
// require("../../vendor/assets/demo1/src/assets/js/global/integration/plugins/sweetalert2.init");

// Bootstrap-Notify
// require("bootstrap-notify");
// require("../../vendor/assets/demo1/src/assets/js/global/integration/plugins/bootstrap-notify.init.js");

// Bootstrap-Datepicker
require("bootstrap-datepicker/dist/css/bootstrap-datepicker3.css");
require("bootstrap-datepicker");
require('bootstrap-datepicker/dist/locales/bootstrap-datepicker.de.min');
require("../../vendor/assets/demo1/src/assets/js/global/integration/plugins/bootstrap-datepicker.init");

// Bootstrap-Datetimepicker
require("bootstrap-datetime-picker/css/bootstrap-datetimepicker.css");
require("bootstrap-datetime-picker");

// Select2
require("select2/dist/css/select2.css");
require("select2");
require("select2/dist/js/i18n/de");
// Bootstrap-Timepicker
require("bootstrap-timepicker/css/bootstrap-timepicker.css");
require("bootstrap-timepicker");
require("../../vendor/assets/demo1/src/assets/js/global/integration/plugins/bootstrap-timepicker.init");

// Tagify
// require("@yaireo/tagify/dist/tagify.css");
// window.Tagify = require("@yaireo/tagify/dist/tagify");
// require("@yaireo/tagify/dist/tagify.polyfills.min");

// Typeahead
window.Bloodhound = require("corejs-typeahead");
window.Handlebars = require("handlebars/dist/handlebars.js");

// Dropzone
require("dropzone/dist/dropzone.css");
window.Dropzone = require("dropzone");
require("../../vendor/assets/demo1/src/assets/js/global/integration/plugins/dropzone.init");

// ClipboardJS
// window.ClipboardJS = require("clipboard");

// Autosize
window.autosize = require("autosize");

// Summernote
// require("summernote/dist/summernote.css");
// require("summernote");

// Quill
// require("quill/dist/quill.snow.css");
// window.Quill = require("quill");

// Inputmask
require("inputmask/dist/jquery.inputmask.bundle");
require("inputmask/dist/inputmask/inputmask.date.extensions");
require("inputmask/dist/inputmask/inputmask.numeric.extensions");

// iOn-Rangeslider
// require("ion-rangeslider/css/ion.rangeSlider.css");
// require("ion-rangeslider");

// jQuery.Repeater
require("jquery.repeater");

// noUISlider
// require("nouislider/distribute/nouislider.css");
// window.noUiSlider = require("nouislider");

// Wnumb
// window.wNumb = require("wnumb");

// jQuery-Validation
require("jquery-validation");
require("jquery-validation/dist/additional-methods.js");
require("../../vendor/assets/demo1/src/assets/js/global/integration/plugins/jquery-validation.init");

// Bootstrap-Multiselectsplitter
// require("../../vendor/assets/demo1/src/assets/plugins/bootstrap-multiselectsplitter/bootstrap-multiselectsplitter.min.js");

// Bootstrap-Maxlength
require("bootstrap-maxlength");

// Bootstrap-Touchspin
// require("bootstrap-touchspin/dist/jquery.bootstrap-touchspin.css");
// require("bootstrap-touchspin");

// Bootstrap-Markdown
// require("bootstrap-markdown/css/bootstrap-markdown.min.css");
// require("bootstrap-markdown/js/bootstrap-markdown");
// require("../../vendor/assets/demo1/src/assets/js/global/integration/plugins/bootstrap-markdown.init");

// Animate.css
require("animate.css/animate.css");

// Dual-listbox
// import DualListbox from "dual-listbox";
// window.DualListbox = DualListbox;
// require("dual-listbox/dist/dual-listbox.css");

// Font Icons
require("../../vendor/assets/demo1/src/assets/plugins/line-awesome/css/line-awesome.css");
require("../../vendor/assets/demo1/src/assets/plugins/flaticon/flaticon.css");
require("../../vendor/assets/demo1/src/assets/plugins/flaticon2/flaticon.css");
require("@fortawesome/fontawesome-free/css/all.min.css");
// require("socicon");
//** End: Global optional plugins


window.KTUtil = require("../../vendor/assets/demo1/src/assets/js/global/components/base/util");
window.KTApp = require("../../vendor/assets/demo1/src/assets/js/global/components/base/app");
window.KTAvatar = require("../../vendor/assets/demo1/src/assets/js/global/components/base/avatar");
window.KTDialog = require("../../vendor/assets/demo1/src/assets/js/global/components/base/dialog");
window.KTHeader = require("../../vendor/assets/demo1/src/assets/js/global/components/base/header");
window.KTMenu = require("../../vendor/assets/demo1/src/assets/js/global/components/base/menu");
window.KTOffcanvas = require("../../vendor/assets/demo1/src/assets/js/global/components/base/offcanvas");
window.KTPortlet = require("../../vendor/assets/demo1/src/assets/js/global/components/base/portlet");
window.KTScrolltop = require("../../vendor/assets/demo1/src/assets/js/global/components/base/scrolltop");
window.KTToggle = require("../../vendor/assets/demo1/src/assets/js/global/components/base/toggle");
// window.KTWizard = require("../../vendor/assets/demo1/src/assets/js/global/components/base/wizard");
// require("../../vendor/assets/demo1/src/assets/js/global/components/base/datatable/core.datatable");
// require("../../vendor/assets/demo1/src/assets/js/global/components/base/datatable/datatable.checkbox");
// require("../../vendor/assets/demo1/src/assets/js/global/components/base/datatable/datatable.rtl");

// Layout Scripts
window.KTLayout = require("../../vendor/assets/demo1/src/assets/js/global/layout/layout");
//window.KTChat = require("../../vendor/assets/demo1/src/assets/js/global/layout/chat");
//require("../../vendor/assets/demo1/src/assets/js/global/layout/demo-panel");
// require("../../vendor/assets/demo1/src/assets/js/global/layout/offcanvas-panel");
// require("../../vendor/assets/demo1/src/assets/js/global/layout/quick-panel");
require("./global/search");


// Datatables.net
import "datatables.net";
import "datatables.net-bs4";
// import "datatables.net-autofill-bs4";
// import "datatables.net-buttons-bs4";
// import "datatables.net-buttons/js/buttons.print.js";
// import "datatables.net-buttons/js/buttons.html5.js";
// import "datatables.net-buttons/js/buttons.flash.js";
// import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-colreorder-bs4";
import "datatables.net-fixedcolumns-bs4";
// import "datatables.net-fixedheader-bs4";
// import "datatables.net-keytable-bs4";
// import "datatables.net-responsive-bs4";
// import "datatables.net-rowgroup-bs4";
// import "datatables.net-rowreorder-bs4";
// import "datatables.net-scroller-bs4";
// import "datatables.net-select-bs4";
import "datatables.net-plugins/sorting/datetime-moment";

require("../../vendor/assets/demo1/src/assets/js/global/integration/plugins/datatables.init.js")

require("datatables.net-bs4/css/dataTables.bootstrap4.css");
// require("datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css");
// require("datatables.net-autofill-bs4/css/autoFill.bootstrap4.min.css");
require("datatables.net-colreorder-bs4/css/colReorder.bootstrap4.min.css");
require("datatables.net-fixedcolumns-bs4/css/fixedColumns.bootstrap4.min.css");
// require("datatables.net-fixedheader-bs4/css/fixedHeader.bootstrap4.min.css");
// require("datatables.net-keytable-bs4/css/keyTable.bootstrap4.min.css");
// require("datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css");
// require("datatables.net-rowgroup-bs4/css/rowGroup.bootstrap4.min.css");
// require("datatables.net-rowreorder-bs4/css/rowReorder.bootstrap4.min.css");
// require("datatables.net-scroller-bs4/css/scroller.bootstrap4.min.css");
// require("datatables.net-select-bs4/css/select.bootstrap4.min.css");


require("jquery.dirtyforms")

require('./datatables')
require('./forms')

let removeFields = require('./nested_forms/remove_fields.js')
let addFields = require('./nested_forms/add_fields.js')

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
require('../../vendor/assets/demo1/src/assets/media/misc/bg-1.jpg')
//
// const images = require.context('../../vendor/assets/demo1/src/assets/media', true)
// const imagePath = (name) => images(name, true)

require('./utils/scrollto.js')


window.moment.locale('de');

$.fn.insertAt = function (index, element) {
    var lastIndex = this.children().size();
    if (index < 0) {
        index = Math.max(0, lastIndex + 1 + index);
    }
    this.append(element);
    if (index < lastIndex) {
        this.children().eq(index).before(this.children().last());
    }
    return this;
}


window.toggler = function (e) {
    if (document.getElementById('user_password').type === "password") {
        e.innerHTML = '<span><i class="la la-eye-slash"></i></span>';
        document.getElementById('user_password').type = "text";
    } else {
        e.innerHTML = '<span><i class="la la-eye"></i></span>';
        document.getElementById('user_password').type = "password";
    }
}

window.trToData = function (row) {
    return $(row).find('td').map(function (i, el) {
        return el.innerHTML;
    }).get();
}

document.addEventListener("turbo:frame-render", () => {
    $('.input-datepicker').each(function (index, item) {

        $(item).inputmask("99.99.9999", {
            "placeholder": "DD.MM.YYYY",
        });

        var arrows = {
            leftArrow: '<i class="la la-angle-left"></i>',
            rightArrow: '<i class="la la-angle-right"></i>'
        }

        $(item).datepicker({
            format: 'dd.mm.yyyy',
            language: 'de',
            todayHighlight: true,
            orientation: $(item).data('orientation') || "bottom left",
            templates: arrows
        })
    })

    $('.kt-select2').select2({
        language: "de",
        width: '100%'
    })

    KTApp.initTooltips();
    KTApp.initPopovers();
})

document.addEventListener("turbo:frame-load", () => {
    KTApp.initTooltips();
    KTApp.initPopovers();
})

// Function to set an item in localStorage with an expiration time (in milliseconds)
function setWithExpiry(key, value, ttl) {
    const now = new Date();
    // `item` is an object that stores the value and the expiration time
    const item = {
        value: value,
        expiry: now.getTime() + ttl,
    };
    localStorage.setItem(key, JSON.stringify(item));
}

// Function to get an item from localStorage, considering the expiration time
function getWithExpiry(key) {
    const itemStr = localStorage.getItem(key);
    // If the item doesn't exist, return null
    if (!itemStr) {
        return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    // If the item is expired, delete it from storage and return null
    if (now.getTime() > item.expiry) {
        localStorage.removeItem(key);
        return null;
    }
    return item.value;
}

$(document).ready(function () {

    new removeFields();
    new addFields();

    $('#invalid-titles').modal();
    $('#invalid-rows').modal();
    $('#bi-export-modal').modal();
    $('#exclusive-warning').modal({
        show: true
    });

    // Event listener for the modal hidden event
    $('#edit-warning-modal').on('hidden.bs.modal', function () {
        // Set local storage with a 12-hour expiry (12 hours * 60 minutes * 60 seconds * 1000 milliseconds)
        setWithExpiry('_vod_sms_edit_warning_modal', 'true', 12 * 60 * 60 * 1000);
    });

    // Check if the modal should be shown based on localStorage
    if (!getWithExpiry('_vod_sms_edit_warning_modal')) {
        $('#edit-warning-modal').modal();
    }

    $("form:not([id*=search]):not(.no-dirty)").dirtyForms();

    $('[name=file_format], [name=document]').on('change', function () {
        if ($("[name=file_format]:checked").val() === "portrait" && $("[name=document]:checked").val() === "catalogue") {
            $('#cover-fields').collapse('show')
        } else {
            $('#cover-fields').collapse('hide')
        }
    })

    $('#title-preparation-bulk-update').on('click', function () {
        let $bulkUpdateForm = $('#title-preparation-bulk-update-modal form');

        $bulkUpdateForm
            .find('input[name^="title_ids"]')
            .remove();

        $(this).closest('form').serializeArray().forEach(function (item) {
            if (item.name === "title_ids[]") {
                $bulkUpdateForm
                    .prepend('<input type="hidden" name="' + item.name + '" value="' + item.value + '"/>')
            }
        })
    });

    $('body').on('click', '.card-title', function (event) {
        if (event.target.tagName !== 'A' && event.target.tagName !== 'BUTTON') {
            $($(this).data('target')).collapse('toggle');
        }
    })

    $(document).on('keypress', function (event) {
        if (event.target.closest("#filter-row") && event.keyCode === 13 && $('#filter-row').length > 0) {
            $('.kt-portlet > form, form[id*=search]').submit()
        }
    })

    function openAnchorAccordion() {
        if (window.location.hash) {
            let $target = $('body').find(window.location.hash.replace('toggle-', ''));
            if ($target.hasClass('collapse')) {
                $target.collapse('show');
            }
        }
    }


    openAnchorAccordion();
});