module.exports = function () {
    let $conditionalToggles = $('[data-behavior="conditional-toggle"]')
    $conditionalToggles.on('switchChange.bootstrapSwitch change', function () {
        toggleConditional(this)
    });

    $conditionalToggles.each(function (index, item) {
        toggleConditional(item)
    })

    function toggleConditional(item) {
        let toggleValue = item.hasAttribute("data-toggle-value") ? $(item).data("toggle-value") : true;
        let toggleContainer = $('#' + $(item).data('toggle'));

        if (item.getAttribute("type") === "radio") {

            toggleValue = typeof toggleValue === 'string' || typeof toggleValue === 'boolean' ? [toggleValue] : toggleValue;
            let checkedRadio = $("[name='" + item.getAttribute("name") + "']:checked");

            let found = false;
            toggleValue.forEach(function (value) {
                if (checkedRadio.val() === value.toString()) {
                    return found = true;
                }
            })

            if (found) {
                toggleContainer.collapse('show')
            } else {
                toggleContainer.collapse('hide')
            }

            if ($(item).data('set-required')) {
                toggleContainer.find('input').prop("required", found)
            }

        } else if (item.nodeName === "SELECT") {
            let selectedText = item.options[item.selectedIndex].text;
            let found = false;

            if (Array.isArray(toggleValue)) {
                toggleValue.forEach(function (value) {
                    if (selectedText === value) {
                        return found = true;
                    }
                });
            } else {
                found = (selectedText === toggleValue);
            }

            if (found) {
                toggleContainer.collapse('show')
            } else {
                toggleContainer.collapse('hide')
            }

            if ($(item).data('set-required')) {
                toggleContainer.find('input[data-require="true"], select[data-require="true"]').prop("required", found)
            }
        } else {
            if ($(item).prop("checked") === toggleValue) {
                toggleContainer.collapse('show')
            } else {
                toggleContainer.collapse('hide')
            }

            if ($(item).data('set-required')) {
                toggleContainer.find('input, input[data-require="true"], select[data-require="true"]').prop("required", $(item).prop("checked") === toggleValue)
            }
        }
    }
}