import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["scrollContainer"]

    static values = {
        collapsed: {type: Boolean, default: false},
    }

    connect() {
        $(this.element).on('show.bs.collapse hide.bs.collapse', () => {
            this.toggleCollapsed()
        })
    }

    collapsedValueChanged() {
        if(this.hasScrollContainerTarget) {
            const height = this.collapsedValue ? this.scrollContainerTarget.firstElementChild.offsetHeight + 30 : 105

            KTUtil.scrollInit(this.scrollContainerTarget, {height: height});
        }
    }

    toggleCollapsed() {
        this.collapsedValue = !this.collapsedValue
    }
}