class FixedColumns {
    constructor(table, options) {
        this.table = table;
        this.$table = $(table.table().node())
        this.options = options;
        this.fixedColumns = [];
        this.fixedLeftColumnsCount = 1;
    }

    init() {
        if (!this.$table.length) {
            return null;
        }

        let self = this;

        self.$table.find('th:nth-child(2)').each(function (i, th) {

            let index = $(th).index();

            self.fixedColumns.push({col: $(th), oldIndex: index, newIndex: index})
        })

        $('th').on('click', '[data-behavior="lock"]', function (event) {
            event.stopPropagation()
            event.preventDefault()

            let $th = $(this).closest('th')
            let index = $th.index()

            if (!$th.hasClass('locked')) {
                $th.addClass('locked')
                $(this).find('i').addClass('fa-lock')
                $(this).find('i').removeClass('fa-unlock')

                self.table.colReorder.move(index, self.options.fixedColumns.leftColumns, true, true)
                self.fixedColumns.push({col: $th, oldIndex: index, newIndex: self.options.fixedColumns.leftColumns})
                self.options.fixedColumns.leftColumns++
                self.fixedLeftColumnsCount++

            } else {
                let original = self.fixedColumns[index - 1]

                let nthChild = parseInt(original.newIndex) + 1
                let $columns = $('th:nth-child(' + nthChild + ')')

                $columns.removeClass('locked')
                $columns.find('i').addClass('fa-unlock')
                $columns.find('i').removeClass('fa-lock')

                // original.col.removeClass('locked')
                // original.col.find('i').addClass('fa-lock')
                // original.col.find('i').removeClass('fa-unlock')

                self.table.colReorder.move(index, original.oldIndex, true, true)

                self.options.fixedColumns.leftColumns--
                self.fixedLeftColumnsCount--
                self.fixedColumns = _.without(self.fixedColumns, original)

            }
            self.table.destroy()
            self.table = self.$table.DataTable(self.options)

            self.relayoutTables()
        })

        $('[data-toggle="dropdown"]').dropdown();

        $('.kt-selectpicker').selectpicker();
    }

    relayoutTables() {
        $.fn.dataTable
            .tables({visible: true, api: true})
            .columns.adjust()
            .fixedColumns().relayout();
    }
}

export default FixedColumns
