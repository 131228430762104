class SubChannels {
    constructor(translations) {
        this.translations = translations
        this.table = $('#sub-channels')
    }

    init() {
        if (!this.table.length) {
            return null;
        }

        let self = this;

        let options = {
            // DOM Layout settings
            dom: `<'row'<'col-sm-12'tr>>
			<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7 dataTables_pager'lp>>`,

            // Order settings
            order: [[1, 'asc']],

            lengthMenu: [10, 25, 50, 100],
            pageLength: 25,
            paging: true,

            language: this.translations,

            bSortCellsTop: true,

            columnDefs: [
                {
                    targets: 5,
                    orderable: false,
                },
                {
                    targets: 6,
                    orderable: false,
                },
                {
                    targets: -1,
                    orderable: false,
                }
            ],
        }

        let table = this.table.DataTable(options);
    }
}

export default SubChannels