import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["toggle", "content", "editButton"]

    static values = {
        open: {type: Boolean, default: false},
    }

    connect() {
    }

    openValueChanged() {
        if (!this.openValue) {
            this.contentTarget.innerHTML = "";
        }

        if (this.hasEditButtonTarget) {
            this._setOpenParam()
        }
    }

    toggle(event) {
        this.openValue = !this.openValue

        if (!this.openValue) {
            event.preventDefault()
            event.stopPropagation()
        }
    }

    _setOpenParam() {
        let url = new URL(this.editButtonTarget.href)
        if (url.searchParams.has("open")) {
            url.searchParams.set("open", this.openValue)
        } else {
            url.searchParams.append("open", this.openValue)
        }

        this.editButtonTarget.href = url
    }
}