import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["input"];
    static values = {
        url: String,
        name: String
    };
    connect() {
        const options = new Bloodhound({
            datumTokenizer: Bloodhound.tokenizers.whitespace,
            queryTokenizer: Bloodhound.tokenizers.whitespace,
            prefetch: {
                url: this.urlValue,
                cache: false
            }
        });

        $(this.inputTarget).typeahead(null, {
            name: this.nameValue,
            source: options
        });
    }
}