import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["sort", "form"]

    static values = {
        sorts: Array
    }

    connect() {
        this.element.style.setProperty("--grid-template", this.gridTemplate)

        document.addEventListener("turbo:frame-render", () => {
            document.querySelectorAll('.tooltip.show').forEach(element => {
                element.remove()
            })
        })
    }

    async sort(event) {
        event.preventDefault();
        await this._setCurrentSorts()
        this.submit()
    }

    submit() {
        this.formTarget.requestSubmit()
    }

    _addSortElement(value) {
        let sortElement = document.createElement("input")
        const nulls = value.nulls !== undefined ? `NULLS ${value.nulls.toUpperCase()}` : ""
        const sort = [value.column, value.order, nulls].filter(n => n).join(" ")

        sortElement.setAttribute("type", "hidden")
        sortElement.setAttribute("name", "sort[]")
        sortElement.setAttribute("value", sort)

        this.formTarget.append(sortElement)
    }

    sortsValueChanged() {
        if (this.hasSortTarget) {
            this._removeSorts()
            this.sortsValue.forEach(sort => {
                this._addSortElement(sort)
                this.sortTargets.find(element => element.dataset.tableColumnParam === sort.column).setAttribute("data-direction", sort.order)
            })
        }
    }

    _setCurrentSorts() {
        let currentSorts = this.sortsValue

        if (currentSorts.findIndex(sortItem => sortItem.column === event.params.column) === -1) {
            if (event.shiftKey) {
                currentSorts.push({column: event.params.column, order: "asc", nulls: event.params.nulls})
            } else {
                currentSorts = [{column: event.params.column, order: "asc", nulls: event.params.nulls}]
            }
        } else {
            if (!event.shiftKey) {
                currentSorts = currentSorts.filter(sortItem => sortItem.column === event.params.column)
            }
            let currentIndex = currentSorts.findIndex(sort => sort.column === event.params.column)
            currentSorts[currentIndex].order = currentSorts[currentIndex].order === "asc" ? "desc" : "asc"
        }
        console.log(currentSorts)
        this.sortsValue = currentSorts
    }

    _removeSorts() {
        this.sortTargets.forEach(element => element.removeAttribute("data-direction"))
        this.formTarget.querySelectorAll("[name='sort[]']").forEach(element => element.remove())
    }

    keyupHandler() {
        if (event.keyCode === 13) {
            this.submit()
        }
    }

    get gridTemplate() {
        const row = this.element.querySelector('.dt_table_row')
        const columns = new Array(...row.children)
        return columns.map(column => {
            switch(true){
                case column.classList.contains("large"):
                    return "minmax(300px, 2fr)"
                case column.classList.contains("medium"):
                    return "minmax(100px, 0.75fr)"
                case column.classList.contains("small"):
                    return "38px"
                default:
                    return "minmax(150px, 1fr)"
            }
        }).join(" ")
    }
}