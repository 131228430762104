import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static values = {
        trigger: {type: String, default: 'hover'},
        skin: String
    }

    connect() {
        $(this.element).popover({
            trigger: this.triggerValue,
            template: '\
            <div class="popover ' + this.skinValue + '" role="tooltip">\
                <div class="arrow"></div>\
                <h3 class="popover-header"></h3>\
                <div class="popover-body"></div>\
            </div>'
        });
    }
}