import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["form", "statusType", "toggle"]

    change() {
        this.formTarget.querySelector("input[type=submit]").disabled = false
    }

    validate(event) {
        event.preventDefault();

        if (this.toggleTarget.checked) {
            this.formTarget.submit()
            return
        }

        let valid = false;

        this.statusTypeTargets.forEach(function (element) {
            if (element.checked) {
                valid = true;
            }
        })

        if (valid) {
            this.formTarget.submit()
        } else {
            this.statusTypeTargets[0].closest('.form-group').classList.add('validated')
        }
    }
}