class TitleDeliveryStatuses {
    constructor(translations) {
        this.translations = translations
        this.table = $('#title-delivery-statuses')
        this.detailRows = []
        this.order = [
            [2, 'asc'],
            [4, 'asc'],
        ];
        this.checkboxIndex = 1;

        this.columnDefs = [
            {
                targets: 0,
                orderable: false,
            },
            {
                targets: 1,
                orderable: false,
            },
            {
                targets: -1,
                orderable: false,
            }
        ];
    }

    init() {
        if (!this.table.length) {
            return null;
        }

        let self = this;

        let options = {
            // DOM Layout settings
            dom: `<'row'<'col-sm-12'tr>>
    		<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7 dataTables_pager'lp>>`,

            // Order settings
            order: this.order,
            autoWidth: false,
            bSortCellsTop: true,
            scrollX: true,
            bProcessing: true,
            bServerSide: true,
            sAjaxSource: this.table.data('source'),
            fnServerParams: function (aoData) {
                self.pushSearchData(aoData)
            },

            headerCallback: function (thead, data, start, end, display) {
                thead.getElementsByTagName('th')[self.checkboxIndex].innerHTML = `
                    <label class="kt-checkbox kt-checkbox--single kt-checkbox--solid">
                        <input type="checkbox" value="" class="kt-group-checkable">
                        <span></span>
                    </label>`;
            },

            lengthMenu: [10, 25, 50, 100],
            pageLength: 25,
            paging: true,

            columnDefs: this.columnDefs,

            language: this.translations,


            "drawCallback": function () {
                setTimeout(function () {
                    $('.dataTables_scrollBody thead tr#filter-row').remove()
                }, 1000)

                $.each(self.detailRows, function (i, id) {
                    $('#' + id + ' .btn-show-childs').trigger('click')
                })

                KTApp.init(KTAppOptions)
            },
            "initComplete": function () {
                setTimeout(function () {
                    $('.dataTables_scrollBody thead tr#filter-row').remove()
                }, 1000)
            },
            "rowCallback": function () {
                setTimeout(function () {
                    $('.dataTables_scrollBody thead tr#filter-row').remove()
                }, 1000)
            },
            createdRow: function (row, data) {
                $(row).attr('data-child', data.DT_ChildRows)
            }
        }

        let table = this.table.DataTable(options);

        $('#filter-row button[data-behavior=search]').on('click', function () {
            self.searchColumns(table)
        });

        $('#filter-row input, #filter-row select').on('keypress', function (event) {
            if (event.keyCode === 13) {
                self.searchColumns(table)
            }
        })

        $('#filter-row button[data-behavior=reset]').on('click', function (e) {
            e.preventDefault();
            document.querySelector('.inline-form').reset();
            self.searchColumns(table)
        });

        let $body = $('body');

        $body.on('change', '.kt-group-checkable', function () {
            var set = $(this).closest('.dataTables_wrapper').find('td .kt-checkable');
            var checked = $(this).is(':checked');

            $(set).each(function () {
                if (checked) {
                    $(this).prop('checked', true).change();
                    $(this).closest('tr').addClass('active');
                } else {
                    $(this).prop('checked', false).change();
                    $(this).closest('tr').removeClass('active');
                }
            });
        });

        self.table.on('change', 'tbody tr .kt-checkbox', function () {
            $(this).parents('tr').toggleClass('active');

            self.checkAtLeastOneChecked(this)
        });

        $('#title-delivery-status-bulk-update,#title-delivery-statuses-sales-sheet').on('click', function () {
            let $form = $($(this).data('target')).find('form');

            let name = self.table.find('tr:not(#filter-row) input.kt-checkable').attr("name")

            $form
                .find('input[name="' + name + '"]')
                .remove();

            $('form.inline-form').serializeArray().forEach(function (item) {
                if (item.name === name) {
                    $form
                        .prepend('<input type="hidden" name="' + item.name + '" value="' + item.value + '"/>')
                }
            })
        });

        self.table.on('click', '.btn-show-childs', function () {
            let tr = $(this).closest('tr')
            let row = table.row(tr)
            let idx = $.inArray(tr.attr('id'), self.detailRows)

            if (row.child.isShown()) {
                tr.attr('data-shown', false)
                row.child.hide()

                self.detailRows.splice(idx, 1)
            } else {
                tr.attr('data-shown', true)
                row.child(tvChildRowFormat(JSON.parse($(tr).attr('data-child')), tr, self.table)).show()

                if (idx === -1) {
                    self.detailRows.push(tr.attr('id'))
                }
            }

            $.fn.dataTable
                .tables({visible: true, api: true})
                .columns.adjust()
        })

    }

    searchColumns(table) {
        table.columns().every((index) => {
            const filterHeading = $('#filter-row th').eq(index);
            const value = filterHeading.find('input[type=text], input[type=search], input[type=checkbox]:checked, select').val() || ""

            if (table.column(index).search() !== value) {
                table
                    .column(index)
                    .search(value)
                    .draw();
            }
        })

        Object.keys(table.ajax.params()).forEach(function (item) {
            $('a[href*=xlsx]').attr('href', updateURLParameter($('a[href*=xlsx]').attr('href'), item, table.ajax.params()[item]));
        })
    }


    checkAtLeastOneChecked(checkbox) {
        if ($(checkbox).closest('.dataTables_wrapper').find('td .kt-checkable:checked').length > 0) {
            $('#title-delivery-status-bulk-update').prop('disabled', false)
        } else {
            $('#title-delivery-status-bulk-update').prop('disabled', true)
        }
    }


    pushSearchData(aoData) {
        aoData.push({"name": "sSearch_2", "value": $('#v_date_gteq').val() + "-" + $('#v_date_lteq').val()});
        aoData.push({"name": "sSearch_9", "value": $('#select_date_gteq').val() + "-" + $('#select_date_lteq').val()});
        aoData.push({"name": "sSearch_12", "value": $('#eta_gteq').val() + "-" + $('#eta_lteq').val()});
    }
}

export default TitleDeliveryStatuses