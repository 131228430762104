module.exports = function () {

    if (document.querySelectorAll(".kt-select2-genres").length <= 0) {
        return
    }

    let primary, secondary;

    $.ajax({
        type: "GET", url: "/admin/genres.json?q[category_eq]=0"
    }).then(function (data) {
        primary = data
    })

    $.ajax({
        type: "GET", url: "/admin/genres.json"
    }).then(function (data) {
        secondary = data
    })

    document.querySelectorAll(".kt-select2-genres").forEach(function (item) {
        $(item).on("select2:select select2:unselect", function (e) {
            let data = $(item).select2('data')
            setTimeout(function () {
                if (data.length >= 1) {
                    $(item).select2({
                        data: secondary, sorter: data => data.sort((a, b) => a.text.localeCompare(b.text)),
                    }).trigger('change')
                } else {
                    $(item).html("")
                    primary.forEach(function (genre) {
                        $(item).append("<option value='" + genre.id + "'>" + genre.text + "</option>")
                    })
                    $(item).select2({
                        data: primary
                    }).trigger('change')
                }
            })
        })
    })
}