import consumer from "./consumer"

consumer.subscriptions.create("LockChannel", {
    connected() {
        // Called when the subscription is ready for use on the server
    },

    disconnected() {
        // Called when the subscription has been terminated by the server
    },

    received(data) {
        // Called when there's incoming data on the websocket for this channel
        if (data.status === "locked") {
            document.querySelectorAll("[data-behavior='lockable'][data-title-id='" + data.title_id + "']").forEach(button => this._lock(button, data));
        } else {
            document.querySelectorAll("[data-behavior='lockable'][data-title-id='" + data.title_id + "']").forEach(button => this._unlock(button, data));
        }

        KTApp.init()
    },

    _lock(button, data) {
        let newButton = this._wrap(button, data.locked_at, data.locked_by)
        newButton.classList.add(...["locked", "disabled"])
        newButton.setAttribute("disabled", "disabled")
    },

    _unlock(button, data) {
        this._unwrap(button.parentElement);
        button.classList.remove(...["locked", "disabled"])
        button.removeAttribute("disabled")
        $(button).modal({show: false})
    },

    _wrap(button, lockedAt, lockedBy) {
        let wrapper = document.createElement('div')
        let buttonClone = button.cloneNode(true)
        wrapper.setAttribute('data-toggle', 'kt-tooltip')
        wrapper.setAttribute('data-original-title', `Titel wird seit ${lockedAt} Uhr von ${lockedBy} bearbeitet`)
        wrapper.classList.add('d-inline-block')
        wrapper.appendChild(buttonClone)
        button.replaceWith(wrapper)
        return buttonClone
    },

    _unwrap(wrapper) {
        wrapper.replaceWith(...wrapper.childNodes)
    }
});
