var initCrews = require('./crews')
var initCasts = require('./crews')
var initSeries = require('./series')
var initCompanies = require('./companies')
var initTvStations = require('./tv_stations')
var initDatepickers = require('./datepicker')
var initConditionals = require('./conditionals')
var initVendorIds = require('./vendor_ids')
var initTitles = require('./titles')
var initTitlesLong = require('./titles_long')
var initTitlesOriginal = require('./titles_original')
var initDropBox = require('./drop_box')
var initGenres = require('./genres')
var initRemoteTitles = require('./remote_titles')

import SalesDelivery from './sales_delivery'

jQuery(document).ready(function () {


    function init() {

        $('[data-switch=true]').bootstrapSwitch();

        $('.kt-select2').select2({
            language: "de",
            width: '100%'
        });

        $('.kt-select2-countries').select2({
            language: "de",
            width: '100%',
            templateSelection: function (option) {
                if(!option.id) {
                    return option.text;
                }
                if(!option.element.dataset.nameShort) {
                    return option.text;
                }
                return option.element.dataset.nameShort;
            }
        });

        $('#title_audio_attributes_audio_other_ids').select2({
            language: "de",
            width: '100%',
            templateSelection: function (option) {
                if(!option.id) {
                    return option.text;
                }
                if(!option.element.dataset.nameShort) {
                    return option.text;
                }
                return option.element.dataset.nameShort;
            },
            templateResult: function (option) {
                if(!option.id || !option.element.dataset.nameShort) {
                    return option.text;
                }

                if ($('#title_audio_attributes_audio_other_ids').data('missing').includes(option.element.dataset.nameShort)) {
                    return $('<span class="is-missing">' + option.text + '</span> <i class="la la-warning text-error"></i>');
                } else {
                    return option.text;
                }
            }
        })

        $('#title_audio_attributes_audio_prepared_ids').select2({
            language: "de",
            width: '100%',
            templateSelection: function (option) {
                if(!option.id) {
                    return option.text;
                }
                if(!option.element.dataset.nameShort) {
                    return option.text;
                }
                return option.element.dataset.nameShort;
            },
            templateResult: function (option) {
                if(!option.id || !option.element.dataset.nameShort) {
                    return option.text;
                }

                if ($('#title_audio_attributes_audio_prepared_ids').data('missing').includes(option.element.dataset.nameShort)) {
                    return $('<span class="is-missing">' + option.text + '</span> <i class="la la-warning text-error"></i>');
                } else {
                    return option.text;
                }
            }
        })

        $('.kt-selectpicker').selectpicker();

        $(".input-duration").inputmask("99:59:59", {
            placeholder: "HH:MM:SS",
            insertMode: false,
            showMaskOnHover: false,
            //hourFormat: 12,
            definitions: {
                '5': {
                    validator: "[0-5]",
                    cardinality: 1
                }
            }
        });

        $(".input-year").inputmask("9999", {});

        $(".input-numeric").inputmask("numeric", {
            rightAlign: false,
            allowMinus: false
        });


        initDatepickers();

        initConditionals();

        initGenres();

        new SalesDelivery().init();

        $('body').on('change', '[data-behavior=copy]', function () {
            if ($(this).prop('checked') === true) {
                $(this).closest('.form-group').find('.form-control').each(function (index, item) {
                    if ($(item).hasClass('tt-input')) {
                        $(item).typeahead('val', $(item).attr('placeholder'))
                    } else {
                        if (item.dataset.copyValue !== undefined) {
                            if (item.nodeName === "SELECT") {
                                $(item).selectpicker('val', item.dataset.copyValue);
                            } else {
                                item.value = item.dataset.copyValue;
                            }
                        } else {
                            $(item).val($(item).attr('placeholder'))
                        }
                    }
                })
            }
        })

        $('#clip_wdrmg_profit_center, #box_set_wdrmg_profit_center').on('change', function () {
            $('[id*=wdrmg_profit_center]').attr('placeholder', $(this).val())
        })

        $('#clip_licensor_share, #box_set_licensor_share').on('change', function () {
            $('[id*=licensor_share]').attr('placeholder', $(this).val())
        })

        $('#box_set_licensor_id').on('change', function () {
            $('[id*=licensor_id]').attr('data-copy-value', $(this).val())
        })

        $('.maxlength').maxlength({
            alwaysShow: true,
            placement: 'bottom-left',
            warningClass: "kt-badge kt-badge--warning kt-badge--rounded kt-badge--inline",
            limitReachedClass: "kt-badge kt-badge--success kt-badge--rounded kt-badge--inline"
        });

        function setFloatingPortlerWidth() {
            let reference = document.querySelector('.col-4 .kt-portlet');

            document.querySelectorAll('.kt-portlet__floating').forEach(function (item) {
                item.style.width = reference.getBoundingClientRect().width + "px";
            })
        }

        if (document.querySelector('.kt-portlet__floating') !== null) {
            setFloatingPortlerWidth();

            window.addEventListener('resize', setFloatingPortlerWidth);

        }

    }

    init();

    initDropBox();

    document.querySelectorAll('.crew-name').forEach(element => {
        initCrews(element);
    })

    document.querySelectorAll('.cast-name').forEach(element => {
        initCasts(element);
    })

    document.querySelectorAll('.series-name').forEach(element => {
        initSeries(element);
    })

    document.querySelectorAll('.company-name').forEach(element => {
        initCompanies(element);
    })

    document.querySelectorAll('.tv-station-name').forEach(element => {
        initTvStations(element);
    })

    document.querySelectorAll('.vendor_ids').forEach(element => {
        initVendorIds(element);
    })
    document.querySelectorAll('.titles-autocomplete').forEach(element => {
        initTitles(element);
    })

    document.querySelectorAll('.titles-long-autocomplete').forEach(element => {
        initTitlesLong(element);
    })

    document.querySelectorAll('.titles-original-autocomplete').forEach(element => {
        initTitlesOriginal(element);
    })

    document.querySelectorAll('.remote-titles').forEach(element => {
        initRemoteTitles(element);
    });

    function scrollToElement(element) {
        let position = element.getBoundingClientRect().top - document.querySelector('#kt_header').offsetHeight - 25;

        window.scrollTo({top: position})
    }


    let openClosestCollapse = function ($element) {
        $element.closest('.collapse').on('shown.bs.collapse', function () {
            scrollToElement(document.querySelector('.form-group.row.validated'));
            $element.closest('.form-control').addClass('is-invalid')
            $element.find('.form-control').addClass('is-invalid')

            if ($element.prop("tagName") === "SELECT") {
                $element.closest('.bootstrap-select').addClass('is-invalid')
            }
        })
        $element.closest('.collapse').collapse('show')

        if ($element.closest('.collapse').closest('.card .collapse').length > 0) {
            openClosestCollapse($element.closest('.collapse').closest('.card'))
        }

    }

    if (document.querySelector('.form-group.row.validated') && window.location.hash.length === 0) {
        let invalidField = $('.form-group.row.validated');
        openClosestCollapse(invalidField)
        scrollToElement(document.querySelector('.form-group.row.validated'))


    }

    document.querySelectorAll('[type=submit]').forEach(function (button) {
        if (!!button.closest('.kt-form') && button.closest('.kt-form').id !== "new_user") {
            button.addEventListener('click', function (event) {

                if (document.querySelector('.kt-form') !== null && document.querySelector('.kt-form').checkValidity() === false) {

                    let firstInvalid = $('.kt-form .form-control:invalid');

                    document.querySelector('.kt-form').classList.add('validated');

                    firstInvalid.closest('.collapse').on('shown.bs.collapse', function () {
                        document.querySelector('.kt-form .form-control:invalid').scrollIntoView();
                        firstInvalid.closest('.form-control').addClass('is-invalid')

                        if (firstInvalid.prop("tagName") === "SELECT") {
                            firstInvalid.closest('.bootstrap-select').addClass('is-invalid')
                        }
                    })
                    firstInvalid.closest('.collapse').collapse('show')

                    return false;
                }
            })
        }
    });


    $('.dropzone-form').each(function (index, item) {
        $(item).dropzone({
            url: $(item).data('url'),
            paramName: $(item).data('param'),
            method: 'put',
            headers: {
                'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
            },
            maxFiles: 1,
            maxFilesize: 5, // MB
            addRemoveLinks: false,
            thumbnailWidth: 200,
            thumbnailHeight: 300
        });
    });

    $('#sales_delivery_series_catch_up_sales').on('switchChange.bootstrapSwitch', function () {
        $.ajax({
            url: $(this).data('ajax-url'),
            type: "PUT",
            success: function () {
                init()
            }
        })
    });


    $('#title_licensor_id, #unknown_lg_licensor_id').on('change', function () {
        $.ajax({
            url: '/admin/licensors/' + $(this).val(),
            type: "GET",
            success: function (data) {
                data = JSON.parse(data)
                $('#title_wdrmg_profit_center, #unknown_lg_wdrmg_profit_center').attr('placeholder', data.wdrmg_profit_center)
                $('#title_licensor_share, #unknown_lg_licensor_share').attr('placeholder', data.licensor_distribution_share)
            }
        })
    })

    $('#alias_title_id').on("select2:select", function () {
        $.ajax({
            url: '/titles/' + $(this).val(),
            type: "GET",
            dataType: "json",
            success: function (data) {
                $('#correct_vendor_id').text(data.vendor_id)
                $('#licensor_info').text(data.licensor_name)
            }
        })
    })

    $(document).on('select2:select', function (e) {
        if (e.target && e.target.classList.contains("box-set-title")) {
            $.ajax({
                url: '/titles/' + e.target.value,
                type: "GET",
                dataType: "json",
                success: function (data) {
                    let container = e.target.closest(".box-set-title-container");
                    container.querySelector(".vendor-id").innerHTML = data.vendor_id
                    container.querySelector(".licensor").innerHTML = data.licensor_name
                }
            })
        }

        if (e.target && e.target.classList.contains("promos-title")) {
            $.ajax({
                url: '/titles/' + e.target.value,
                type: "GET",
                dataType: "json",
                success: function (data) {
                    console.log(data)
                    let container = e.target.closest(".promos-title-container");
                    container.querySelector(".vendor-id").innerHTML = data.vendor_id
                }
            })
        }
    })

    $("body").on("click", ".inline-form a[data-remote=true], .cancel-inline-form", function () {
        let currentEdit = $(".inline-form").find("[data-view]");
        let output = currentEdit.data("view");

        currentEdit.replaceWith(output);
    })

    $(document).on('changed.bs.select shown.bs.collapse hidden.bs.collapse', function () {
        setTimeout(function () {
            if (typeof $.fn.dataTable.tables({visible: true, api: true}).fixedColumns == "function") {
                $.fn.dataTable
                    .tables({visible: true, api: true})
                    .columns.adjust()
                    .fixedColumns().relayout()
            } else {
                $.fn.dataTable
                    .tables({visible: true, api: true})
                    .columns.adjust()
            }

            $('.dataTables_scrollBody thead tr#filter-row').remove()
        })
    })

});