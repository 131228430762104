class TimingPreorders {
    constructor(translations) {
        this.translations = translations
        this.table = $('#timings-preorders')
    }

    init() {
        if (!this.table.length) {
            return null;
        }

        let self = this;

        let options = {
            // DOM Layout settings
            dom: `<'row'<'col-sm-12'tr>>
			<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7 dataTables_pager'lp>>`,

            // Order settings
            ordering: false,
            autoWidth: false,
            bSortCellsTop: true,
            scrollX: true,
            bProcessing: true,
            bServerSide: true,
            sAjaxSource: this.table.data('source'),

            lengthMenu: [10, 25, 50, 100],
            pageLength: 10,
            paging: true,

            language: this.translations,
        }

        let table = this.table.DataTable(options);
    }
}

export default TimingPreorders