module.exports = function (element) {
    var arrows = {
        leftArrow: '<i class="la la-angle-left"></i>',
        rightArrow: '<i class="la la-angle-right"></i>'
    }

    $('.input-datepicker').each(function (index, item) {

        $(item).inputmask("99.99.9999", {
            "placeholder": "DD.MM.YYYY",
        });

        $(item).datepicker({
            format: 'dd.mm.yyyy',
            language: 'de',
            todayHighlight: true,
            orientation: $(item).data('orientation') || "bottom left",
            templates: arrows
        }).on('changeDate', function () {
            const event = new Event("input")
            $(this)[0].dispatchEvent(event)
            
            setTimeout(function () {
                $.fn.dataTable
                    .tables({visible: true, api: true}).draw()
            })
        })
    })
}