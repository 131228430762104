module.exports = function (element) {
    $(element).select2({
        placeholder: "nach Titel suchen",
        minimumInputLength: 1,
        language: "de",
        ajax: {
            url: element.dataset.url,
            dataType: 'json',
            data: function(params) {
                return {
                    q: {
                        title_or_title_long_cont: params.term
                    },
                };
            },
            processResults: function (data) {
                return {
                    results: data
                };
            },
            cache: true
        },
    })
}