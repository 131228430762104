import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["filter", "toggle"]

    static values = {
        filter: {type: Boolean, default: false},
    }

    connect() {
    }

    filterValueChanged() {
        this.filterTarget.hidden = !this.filterValue
        this.toggleTarget.classList.toggle("collapsed")
        this.toggleTarget.setAttribute('aria-expanded', this.filterValue)
    }

    toggleFilter() {
        this.filterValue = !this.filterValue
    }
}