module.exports = function (element) {
    var titles = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.whitespace,
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        prefetch: {
            url: element.dataset.url,
            cache: false
        }
    });

    $(element).typeahead(null, {
        name: 'titles',
        source: titles
    });
}